///*------------------------------------*\
//    #CORPORATE
//\*------------------------------------*/

.corporate__img-corner {
    position: absolute;
    left: -1px;
    top: -1px;
}
.corporate__title {
    margin-left: $inuit-base-spacing-unit--large;
    padding-left: $inuit-base-spacing-unit;
}





// WHITE CONTENT
.corporate__white {
    background-color: white;
    border: 1px solid #cdcdce;
    padding: 20px 25px;
    margin-bottom: $inuit-base-spacing-unit;
    @include media-query(palm){
        margin-bottom: $inuit-base-spacing-unit--small;
    }
}

.corporate__col-bolivar {
    @include span(10 of 18);
    @include media-query(lap-and-up){
        margin-top: 80px;
    }
    @include media-query(palm){
        @include span(full)
    }
}

.corporate__col-login {
    @include span(full)
}

.corporate__col-description {
    @include span(1 of 2);
    @include media-query(palm){
        @include span(full)
    }
}





// COLORED BOXES
.corporate__description-content {
    color: white;
    padding: $inuit-base-spacing-unit;
    position: relative;
    @include media-query(lap){
        min-height: 530px;
    }
    @include media-query(desk){
        min-height: 365px;
    }
}
.corporate__description-arrow {
    left: calc(50% - #{$inuit-base-spacing-unit});
    position: absolute;
    top: -1px;
    width: $inuit-base-spacing-unit--large;
}
.corporate__description-title.corporate__green {
    color: $color-corporate-green;
}
.corporate__description-title.corporate__blue {
    color: $color-corporate-blue;
}
.corporate__description-content.corporate__green {
    background-color: $color-corporate-green;
}
.corporate__description-content.corporate__blue {
    background-color: $color-corporate-blue;
}
.corporate__description-content * {
    line-height: 1.5;
}

.corporate .corporate--hero {
    background-color:#FAFBFE;
    padding-top:35px;
    padding-bottom:35px;
    margin-bottom: 45px;
}

.corporate .corporate--hero .info {
    padding-right: 5.5vw;
}

.corporate .corporate--hero .info p {
    color: #353535;
    text-align: justify;
    line-height: 18px;
    font-size: 14px;
    padding-top: 15px;
    margin-bottom: 30px;
}

.corporate .corporate--hero .info .col-6 {
    padding-left: 0 !important;
}

.corporate .corporate--hero .info .btn {
    font-size: 14px;
    line-height: 21px;
}

.corporate .corporate--hero .info .login {
    background-color: transparent !important;
    box-shadow: none !important;
    color: #2477CA !important;
}

.corporate .corporate--hero .info .tel {
    color: #2171C3;
    font-size: 16px;
    line-height: 21px;
    display: block;
    width: fit-content;
}

.corporate .corporate--hero .info .btn--submit {
    padding: 13px 30px !important;
}

.corporate .corporate--hero .info span.material-icons-round {
    transform: translateY(5px);
    margin-right: 10px;
}

.corporate .title {
    color: #2477CA;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    margin-bottom: 40px;
    font-weight: bold;
}

.corporate .content .container-corporate {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 25px 21px;
    grid-auto-flow: row;
    grid-template-areas:
        "a1 a2 a3 a4"
        "a5 a6 a7 a8";
}

.corporate .content .container-corporate2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 25px 12vw;
    grid-auto-flow: row;
    grid-template-areas:
        "a1 a2 a3"
        "a4 a5 a6";
}

.corporate .content hr {
    border-bottom: 1px solid #B5B5B5 !important;
    margin-bottom: 15px !important;
}

.corporate .content .box-benefit {
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
}

.corporate .content .box-benefit p {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #323232;
    margin-top: 16px;
}

.corporate .content .container-corporate .a1 { grid-area: a1; }
.corporate .content .container-corporate .a2 { grid-area: a2; }
.corporate .content .container-corporate .a3 { grid-area: a3; }
.corporate .content .container-corporate .a4 { grid-area: a4; }
.corporate .content .container-corporate .a5 { grid-area: a5; }
.corporate .content .container-corporate .a6 { grid-area: a6; }
.corporate .content .container-corporate .a7 { grid-area: a7; }
.corporate .content .container-corporate .a8 { grid-area: a8; }

.corporate .content .container-corporate .b1 { grid-area: b1; }
.corporate .content .container-corporate .b2 { grid-area: b2; }
.corporate .content .container-corporate .b3 { grid-area: b3; }
.corporate .content .container-corporate .b4 { grid-area: b4; }
.corporate .content .container-corporate .b5 { grid-area: b5; }
.corporate .content .container-corporate .b6 { grid-area: b6; }

@media screen and (max-width: 705px) {
    .corporate .content .container-corporate {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        "a1 a2"
        "a3 a4"
        "a5 a6"
        "a7 a8";
    }

    .corporate .content .container-corporate2 {
        gap: 25px 21px;
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
        "b1 b2"
        "b3 b4"
        "b5 b6";
    }
}

// VIDEO FOOTER
.corporate__video {
    margin-top: 30px;
    max-width: 500px;
}
.corporate__video-play:hover {
    background-color: #444;
    box-shadow: inset 0 0 10px #333;
}
.corporate__video-play:active {
    background-color: #333;
    box-shadow: inset 0 0 10px #222;
}
.corporate__video-arrow {
    height: 46px;
}
.corporate__video-play i {
    font-size: 50px;
}
.corporate__video-play p {
    color: white;
    font-size: 12px;
    font-weight: bold;
    position:relative;
    bottom:98px;
    padding-top:31px;
}
.corporate__video-btn {
    background-color: #009BF8;
    color: white;
    display: inline-block;
    font-size: 24px;
    margin-top: 15px;
    padding: 10px 25px;
    width: 74%;
    margin-left: 5%;
    text-align:center;
    float:left;

}
.corporate__video-btn:hover {
    background-color: #444;
    color: white;
    box-shadow: 0 10px 6px -6px #888, inset 0 0 10px #333;
}
.corporate__video-btn:active, .corporate-video-btn:focus {
    background-color: #333;
    color: white;
    box-shadow: 0 10px 6px -6px #888, inset 0 0 10px #222;
}
.corporate__video-btn p{
    font-size: 16px;
    margin: 0 0 4px;
}
.corporate__video-iframe {
    width: 100%;
    min-height: 300px;
}
.corporate__phone {
    margin-top: 30px;
    font-size: 25px;
}
.corporate__container_top_cor{
    width:49%;
    font-size:19px;
    float:left;
    text-align: justify;
}
@media screen and (max-width: 921px) {
    .corporate_column_top_cor, .columnas_top_cor {width:100%;}
    .responsive_img_corp img {text-align:center; margin-bottom:20px;}
}

.corporate__center-block{
    display: block;
    float: left;
    text-align: center;
}



.corporate_column_top_cor {
    float: left;
    font-size: 19px;
    text-align: justify;
    width: 49%;
}

.corporate_column_top_cor{
    width:49%;
    /*juan*/
    font-size:19px;
    /*fin*/
    float:left;
    text-align: justify;
}

.corporate_column_top_cor .center-block{
    text-align: center;
}
.corporate__video-play {
    background-color: #555555;
    border-radius: 7px;
    float: left;
    height: 100px;
    max-width: 100px;
    padding: 20px 0;
    width: 21%;
}
.corporate-video-iframe{
    width: 100%;
    height: 500px;
}