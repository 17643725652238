///*------------------------------------*\
//    #PROMO-BOX
//\*------------------------------------*/

// Internal variable
$promo-box-height: 260px;
$promo-box-height-small: 200px;

.spacing-section{
    padding: 50px 0px 2px;
    margin-bottom: 0;
    @include media-query(palm) {
        padding: 50px 0px 1px;
    }
}
.promo-box__grid  {
    @include span(1 of 3);
    @include media-query(lap) {
        @include span(1 of 2);
    }
    @include media-query(palm) {
        @include span(full);
        margin-left: -$wrapper-padding-spacing-unit--small;
        margin-right: -$wrapper-padding-spacing-unit--small;
        width: calc(100% + #{$wrapper-padding-spacing-unit});
    }
}
.promo-box__grid_2  {
    @include span(2 of 3);
    @include media-query(lap) {
        @include span(2 of 2);
    }
    @include media-query(palm) {
        @include span(full);
        margin-left: -$wrapper-padding-spacing-unit--small;
        margin-right: -$wrapper-padding-spacing-unit--small;
        width: calc(100% + #{$wrapper-padding-spacing-unit});
    }
}

.promo-box__grid_3  {
    @include span(3 of 3);
    @include media-query(lap) {
        @include span(2 of 2);
    }
    @include media-query(palm) {
        @include span(full);
        margin-left: -$wrapper-padding-spacing-unit--small;
        margin-right: -$wrapper-padding-spacing-unit--small;
        width: calc(100% + #{$wrapper-padding-spacing-unit});
    }
}
.promo-box__grid_4  {
    width: 394px;
    height: 554px;
    float: left;
    padding-left: 9px;
    padding-right: 9px;
    margin-bottom: $wrapper-padding-spacing-unit;
    @include media-query(lap) {
        @include span(2 of 2);
    }
    @include media-query(palm) {
        @include span(full);
    }
    &:hover {
        transform: scale(1.015);
    }
}

/* ----------- iPad----------- */
/* Portrait and Landscape */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1.5) {
    .promo-box__grid_4  {
        width: 375px;
    }
}

/* ----------- iPad Pro ----------- */
/* Portrait and Landscape */
@media only screen 
and (min-device-width: 1024px) 
and (max-device-width: 1366px) 
and (-webkit-min-device-pixel-ratio: 1.5) {
    .promo-box__grid_4  {
        width: 335px;
    }
}
.promo-box__bf {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 554px;
    position: relative;
    text-align: center;
    width: 100%;
    cursor: pointer;
    /*    transition: all .45s;*/
    @include material-shadow(1);
}
.promo-box {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: $promo-box-height;
    margin-bottom: $wrapper-padding-spacing-unit;
    position: relative;
    text-align: center;
    width: 100%;    
    @include material-shadow(1);
    @include media-query(small) {
        height: $promo-box-height-small;
    }
}
.promo-box>h2{
    float:right;
    margin:200px 16px 0 0;
    text-shadow:-2px 2px 2px #000;
    color:#fff;
}

.promo-box:hover {
    @include material-shadow(2);  
}
.promo-box__title {
    /* background-color: rgba(black, .5);*/
    padding-left: $inuit-base-spacing-unit--tiny;
    padding-right: $inuit-base-spacing-unit--tiny;
}
.promo-box__title>h3 {
    text-align: left;
    margin: 1.57em;
    line-height: 1.3;
    font-size: 1.6rem;
    text-shadow:-2px 2px 2px #000;
    @include media-query(small) {
        margin: 1.2em !important;
    }
}
.promo-box__subtitle {
    /*background-color: rgba(black, .5);*/
    bottom: 0;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    width: 100%;
    right:0;
    bottom:0;
    margin: 3em 2.3em;
    text-shadow: -2px 2px 2px #000000;
    padding-right: 15px;
    max-width:260px;
    border-right: 2px solid #fff;
    text-align:right;   
    @include media-query(small) {
        margin: 1.8em 2em !important;
    }
}
.promo-box__title{
    padding: $inuit-base-spacing-unit--tiny 0;
    width: 100%;
    transition: all .45s;
}
.promo-box__title-banner{
    padding: $inuit-base-spacing-unit--tiny 0;
    width: 50%;
    transition: all .45s;
}
/*.promo-box:hover .promo-box__title, .promo-box:hover .promo-box__subtitle {
    background-color: rgba($color-main, .8);
}*/
.promo-box__title *, .promo-box__subtitle * {
    color: white;
    margin-bottom: 0;
    @include font-weight(bold);
}
.promo-box__subtitle * {
    line-height: 1.2;
}

.promo-box__link {
    background-color: rgba(black, .65);
    color:#fff;
    opacity: 0;
    padding: $inuit-base-spacing-unit--small 0;
    position: absolute;
    top: 0;
    width: 100%;
    transition: opacity .45s;
    @include font-size(14px);
    @include font-weight(bold);
    box-shadow: 0 4px 20px -10px rgba(0,0,0,.75);
}
.promo-box:hover  .promo-box__link,.promo-box__bf:hover  .promo-box__link {
    opacity: 1;
    text-align: center;
}
.promo-box__conditions {
    background-color: rgba(black, .65);
    bottom: 0;
    color:#fff;
    cursor: pointer;
    opacity: 0;
    padding: $inuit-base-spacing-unit--small;
    position: absolute;
    width: 100%;
    transition: opacity .45s;
    @include font-size(11px);
    box-shadow: 0 -4px 20px -10px rgba(0,0,0,.75);
    .promo-box & {
        @include attention() {
            opacity: 1;
            z-index: 1;
        }
    }
    .promo-box__bf & {
        @include attention() {
            opacity: 1;
            z-index: 1;
        }
    }
}



/* _______________________________________________ */

/* CONTAINER */
/* _______________________________________________ */

.carousel, .carouselh, .carouselb{
    position: relative;
    margin: 0 auto;
    height: auto;
    border: none;
    overflow: hidden;
    &.one{
        width: 1168px;
        .nextButton {
            position: absolute;
            right: 5px;
            top: 50%;
            width: 35px;
            height: 35px;
            background:$color-brand;
            border-radius: 50%;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 35px;
            font-size: 22px;
            cursor: pointer;
            z-index: 999;
            box-shadow: 0px 0px 6px 1px black;
        }
        .prevButton {
            position: absolute;
            left: 5px;
            top: 50%;
            width: 35px;
            height: 35px;
            background:$color-brand;
            border-radius: 50%;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 35px;
            font-size: 22px;
            cursor: pointer;
            z-index: 999;
            box-shadow: 0px 0px 6px 1px black;
        }
    }
    &.double{
        width: 1120px;
        .nextButton {
            position: absolute;
            right: 0;
            top: 50%;
            width: 35px;
            height: 35px;
            background: #0396ee;
            border-radius: 50%;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 35px;
            font-size: 22px;
            cursor: pointer;
            z-index: 999;
        }
        .prevButton {
            position: absolute;
            left: 0;
            top: 50%;
            width: 35px;
            height: 35px;
            background: #0396ee;
            border-radius: 50%;
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: 35px;
            font-size: 22px;
            cursor: pointer;
            z-index: 999;
        }
    }
    .slides, .slidesh {
        margin: 0;
        padding: 0;
        border: none;
        list-style: none;
        position: relative;
        overflow: visible;
        .slideItem, .slideItemH {
            position: absolute;
            cursor: pointer;
            overflow: hidden;
            padding: 0;
            margin: 0;
            border-radius: 10px;
            a {
                display: block;
                text-decoration: none;
                margin: 0;
                padding: 0;
                border: none;
                outline: none;
            }
            img {
                margin: 0;
                padding: 0;
                border: none;
                width: 100%;
                height: 100%;
            }
        }
    }
    .buttonNav {
        position: relative;
        margin: 0 auto;
        z-index: 999;
        .bullet {
            position : relative;
            float : left;
            width : 10px;
            height : 10px;
            background-color: #a9d5ff;
            border-radius: 50%;
            margin : 5px;
            cursor : pointer;
            &:hover {
                background-color : #005eb8
            }
        }
        .bulletActive {
            background-color : #005eb8;
        }
    }
}

.pic-tit {
    text-shadow: -2px 2px 2px #000000;
    padding: 10px;
    width: 100%;
    height: 70px;
    background: rgba(0, 123, 191, 0.6);
    position: absolute;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
    //z-index: 1;
    text-align: center;
    color: #FFF;
    overflow: hidden;
    h4 {
        font-size: 18px;
        text-shadow: -2px 2px 2px #000000;
        line-height: 1.2;
        color: white;
    }
}
.bold-label{
    font-weight: bold;
}
.flex-center{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.title-carousel{
    @include media-query(palm){
        padding-left: 10px;
    }
}
/*Estilos nuevo slide HTML*/
.img-slide {
    background-size: cover;
    height: 230px;
    background-position: center;
}
.info-slide {
    padding: 20px 14px;
    text-align: left;
}

.price-info {
    font-size: 1.5rem;
    color: #005eb8;
}
.product-info{
    min-height: 180px;
    h4 {
        letter-spacing: .025em;
        line-height: inherit;  
    }    
    .p-info {
        font-size: 16px;
        color: #444;
    }
}
.subtitle-info {
    color: #000;
    .btn{
        width:100%;
    }
    p{
        margin:0.6rem 0;
    }
}
.inner-slide, .background-white{
    background-color: #fff;
}
.spacing-section{
    padding: 50px 0px 0px;
}
.blog-slide{
    width: 32%;
    float: left;
    display: flex;
    list-style: none;
    text-align: left;    
    margin-right: 23px;
    img{
        height: 100%;
        width: 100%;    
    }
    .box-blog_content {
        line-height: inherit;
        font-weight: 600;
        min-height: 44px;
    }
    .box-content {
        min-height: 72px;
    }
    &:last-of-type{
        margin-right: 0px;
    }
    @include media-query(palm) {
        width: 100%;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    @include media-query(lap){
        width: 50%;
        margin: 0 0 5px 0px;
        padding: 12px;
        &:last-child{
            margin-bottom: 0;
            padding-bottom: 0;;
        }
    }
    @media screen and (min-width:1023px) and (max-width:1185px) {
        width: 31%;
    }
}
.btn--slide-scroll {
    width: 40px;
    padding: 9px;
    border-radius: 50%;
    margin: 50px auto 20px;
    text-align: center;
    color: #005EB8;
    border: 2px solid #005EB8;
    &:hover > .icon{   
        -moz-animation: bounce 1s;
        -webkit-animation: bounce 1s;
        animation: bounce 1s 2;   
    }
}
@keyframes bounce {
    0%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    56% {
        -moz-transform: translateY(4px);
        -ms-transform: translateY(4px);
        -webkit-transform: translateY(4px);
        transform: translateY(4px);
    }
}

/*Promos para preventa*/

.card{
    .img-slide img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .info-slide-preventa {
        padding: 20px 0 0;
        .product-info-preventa {
            min-height: 100px;
            padding: 0 14px;
            h4 {
                font-size: 1.25rem;
                font-weight: 300;
                letter-spacing: .025rem;
                line-height: 1.2;
                p {
                    &.tipo {
                        font-size: 14px;
                        color: #444;
                        text-transform: uppercase;
                        margin: 0;
                    }

                    &.destino {
                        font-size: 20px;
                        font-weight: 600;
                        color: #000;
                        margin-bottom: 0px;
                    }

                    &.trayecto {
                        font-size: 16px;
                        color: #005eb8;
                        margin-bottom: 1.125rem;
                    }
                }
            }
        }
    }
} 
.subtitle-info-preventa {
    color: #000;
    font-size: 16px;
    font-weight: 300;
    padding: 0 14px 14px;
    p {
        margin: 0;
        .precio-preventa {
            font-size: 24px;
            color: #005eb8;
            margin: 0;
        }
    }
}

.btn-preventa {
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    padding: 5px 20px;
    color: #fff;
    background-color: #255eb2;
    margin: 0;
    border: 0;
    border-radius: 0 0 10px 10px;
    box-shadow: none;
    text-align: center;
}
/*Promos para black week*/

.card.black{
    background-color: #222;
    .img-slide img{
        display: block;
        width: 100%;
        height: 100%;
    }
    .info-slide-black {
        padding: 20px 0 0;
        .product-info-black {
            min-height: 100px;
            padding: 0 14px;
            h4 {
                font-size: 1.25rem;
                font-weight: 300;
                letter-spacing: .025rem;
                line-height: 1.2;
                p {
                    &.tipo {
                        font-size: 14px;
                        color: #f5f5f5;
                        text-transform: uppercase;
                        margin: 0;
                    }

                    &.destino {
                        font-size: 20px;
                        font-weight: 600;
                        color: #fff;
                        margin-bottom: 0px;
                    }

                }
            }
            .trayecto {
                font-size: 16px;
                color: #0ee5fe;
                margin-bottom: 1.125rem;
            }
        }
    }
} 
.subtitle-info-black {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    padding: 0 14px 14px;
    p {
        margin: 0;
        color: #fff;
        .precio-black {
            font-size: 24px;
            color: #0ee5fe;
            margin: 0;
        }
    }
}

.btn-black {
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    padding: 5px 20px;
    background-color: #fff;
    //background-image: linear-gradient(60deg, #005eb8, #0ee5fe);
    margin: 0;
    border: 0;
    border-radius: 0 0 10px 10px;
    box-shadow: none;
    text-align: center;
    color: #2765b6;
}

#carouselh__experience .owl-stage {
    transform: translate3d(0px, 0px, 0px);
    transition: all 0.85s ease 0s;
    width: 1880px !important;
}

#carouselh__experience .owl-stage .owl-item {
    width: 220px !important;
    margin-right: 15px !important;
    height: 336px !important;
}

#carouselh__experience .nav-btn.nav-btn-slideh {
    top: -230px !important;
}
